<template>
  <div class="home">
    <div class="banner">
      <Carousel :images="bannerImgList" :interval="5000" :indicator="false" />
    </div>
    <div class="home-container">
      <div class="content-wrapper">
        <div class="avs-content pt80">
          <h2>One-stop Visual Solutions</h2>
          <PreviewCarousel :avsList="avsList" :cpssList="cpssList" />
        </div>
        <!-- <div class="cpss-content">
          <h2>Computational Photography Solutions for Smartphone</h2>
          <PreviewCarousel :sourceList="cpssList" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue';
import { routePath } from '@/router/routePath.js';
import PreviewCarousel from './components/NewPreviewCarousel.vue';
export default {
  name: 'Home',
  metaInfo: {
    title: 'ArcSoft | Intelligent Driving and Imaging  Technologies',
    meta: [
      {
        name: 'description',
        content: `Leading in autonomous driving and computational photography technology, ArcSoft provide advanced vision solutions for driver assistance and autonomous driving vehicles. ArcSoft's industry-leading imaging solutions also power mobile devices, delivering intelligent photography capabilities for cutting-edge user experiences.`,
      },
      {
        name: 'keywords',
        content: `autonomous driving, driver assistance, self-driving technology, computational photography, smart imaging solutions, intelligent photography, vision solutions, mobile smart devices, automotive vision systems, AI-powered driving, advanced driving technology`,
      },
    ],
  },
  components: {
    Carousel,
    PreviewCarousel,
  },
  data() {
    return {
      bannerImgList: [
        '/image/home/banner1.jpg',
        '/image/home/banner2.jpg',
        '/image/home/banner3.jpg',
      ],
      avsList: [
        {
          key: 'InCabin',
          name: 'In-cabin',
          title: 'In-cabin Visual Software Solutions',
          // toPath: "/in-cabin",
          imgSrc: '/image/home/avs-1.jpg',
          hoverImg: '/image/home/avs-r1.jpg',
          uid: 1,
        },
        {
          key: 'APA',
          name: 'Auto-Parking Assist',
          title: 'Auto-Parking Assist (APA) Solutions on a Single SoC',
          // toPath: "/apa",
          imgSrc: '/image/home/avs-2.jpg',
          hoverImg: '/image/home/avs-r2.jpg',
          uid: 2,
        },
        {
          key: 'ADAS',
          name: 'ADAS',
          title: 'Integrated ADAS Solutions',
          // toPath: "/adas",
          imgSrc: '/image/home/avs-3.jpg',
          hoverImg: '/image/home/avs-r3.jpg',
          uid: 3,
        },
        {
          key: 'CommercialVehicles',
          name: 'Commercial Vehicles',
          title: 'Integrated Solutions for Commercial Vehicles',
          // toPath: "/commercial-vehicles",
          imgSrc: '/image/home/avs-4.jpg',
          hoverImg: '/image/home/avs-r4.jpg',
          uid: 4,
        },
      ],
      cpssList: [
        {
          key: 'TurboFusion',
          name: 'Turbo Fusion™ Products',
          title: 'Turbo Fusion™ Products',
          // title: "Turbo Fusion Products for Smartphone",
          // toPath: "/turbo-fusion",
          imgSrc: '/image/home/cpss-1.jpg',
          hoverImg: '/image/home/cpss-r1.jpg',
          uid: 1,
        },
        {
          key: 'AigcSolutions',
          name: 'AIGC Large Model Solutions',
          // title: "AIGC Large Model Solutions for Smartphone",
          title: 'AIGC Large Model Solutions',
          // toPath: "/aigc-solutions",
          imgSrc: '/image/home/cpss-2.jpg',
          hoverImg: '/image/home/cpss-r2.jpg',
          uid: 2,
        },
      ],
    };
  },
  methods: {
    getRoutePath(key) {
      return routePath[key] || '';
    },
  },
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  img {
    width: 100%;
  }
}

h2 {
  font-size: 40px;
  line-height: 56px;
  text-align: center;
  margin-bottom: 40px;
}

.home-container {
  // padding: 80px 0;
}

.content-wrapper {
  max-width: 1920px;
  margin: 0 auto;
}

.avs-content {
  margin-bottom: 80px;
}

.preview-container {
  width: 100%;
  // height: 800px;
  aspect-ratio: 1920 / 800;
  display: flex;
  overflow: hidden;
  .preview-item-left {
    width: 82%;
    height: 100%;

    background-color: #fafafa;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
    .current-title {
      position: absolute;
      left: 40px;
      bottom: 40px;
      font-size: 40px;
      color: #fafafa;
    }
  }
  .preview-item-right {
    width: 18%;
    background-image: linear-gradient(0deg, #393939 0%, #555555 100%);
    a:last-child .preview-item-right-to {
      border-bottom: none;
    }
    .preview-item-right-to {
      box-sizing: border-box;
      width: 100%;
      // height: 200px;
      aspect-ratio: 340 / 200;
      background-image: linear-gradient(0deg, #555555 0%, #393939 100%);
      border: solid 1px #d9d9d9;
      color: #fafafa;
      padding: 16px 24px;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
      &::before {
        content: '';
        width: 4px;
        height: 100%;
        background-color: #e83021;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.3s;
      }
      .hover-img {
        position: absolute;
        left: 0;
        top: 0;
        transform: translateX(100%);
        transition: transform 0.3s;
        width: 100%;
        height: 100%;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      &.current-hover {
        &::before {
          opacity: 1;
        }

        .hover-img {
          transform: translateX(0);
        }
      }
      h4 {
        z-index: 1;
      }
      .icon img {
        position: relative;
        transform: translateX(-80px);
        filter: drop-shadow(#fafafa 80px 0);
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
      &:first-child {
        border-top: none;
      }
    }
  }
}

/deep/ .cpss-content {
  .preview-container {
    .preview-item-right-to {
      aspect-ratio: 340 / 394;
    }
  }
}
</style>
