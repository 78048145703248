<template>
  <div class="previewCarousel">
    <div class="automotive nav-box" ref="Automotive">
      <!-- <img src="/image/home/avs-1.png" alt="" /> -->
      <div class="nav-list">
        <h2>Automotive Visual Solutions</h2>
        <router-link
          :to="getRoutePath(item.key)"
          v-for="(item, index) in avsList"
          :key="index"
        >
          <p>{{ item.name }}</p>
        </router-link>
      </div>
    </div>
    <div class="photography nav-box" ref="Photography">
      <!-- <img src="/image/home/cpss-1.png" alt="" /> -->
      <div class="nav-list">
        <h2 class="photography-h2">
          Computational Photography Solutions for Smartphone
        </h2>
        <router-link
          :to="getRoutePath(item.key)"
          v-for="(item, index) in cpssList"
          :key="index"
        >
          <p>{{ item.name }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { routePath } from '@/router/routePath.js';
export default {
  props: {
    avsList: {
      type: Array,
      default: () => [],
    },
    cpssList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getRoutePath(key) {
      return routePath[key] || '';
    },
  },
};
</script>
<style lang="less" scoped>
.previewCarousel {
  display: flex;
  align-items: center;
  width: 1720px;
  margin: 0 auto;
  justify-content: space-between;
  gap: 40px;
  .nav-box {
    height: 665px;
    transition: all 0.2s ease;
    overflow: hidden;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    // .photography-h2 {
    //   padding: 0 80px;
    // }
    .nav-list {
      color: #fff;
      h2 {
        font-size: 40px;
        margin-bottom: 122px;
      }
      p {
        transition: all 0.5s ease;
        opacity: 0;
        font-size: 20px;
        margin-bottom: 32px;
        transform: translateX(50px);
      }
    }
  }
  .automotive,
  .photography {
    width: 840px;
  }
  .automotive:hover {
    width: 1040px;
    flex-shrink: 0;
    p {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .photography:hover {
    width: 1040px;
    flex-shrink: 0;
    p {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .automotive {
    background: url(/image/home/avs-1.png) center center;
  }
  .photography {
    background: url(/image/home/cpss-1.png) center center;
  }
}
</style>
